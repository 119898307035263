import 'styles/global/footer';

document.addEventListener('DOMContentLoaded', () => {
  const COLLAPSE = 'collapse';

  ['about', 'solution', 'client', 'connect'].forEach(id => {
    const link = document.querySelector(`#footer_links #${id} a`);
    const menu = document.getElementById(`${id}_links`);

    link && link.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      [...menu.classList].includes(COLLAPSE) ? menu.classList.remove(COLLAPSE) : menu.classList.add(COLLAPSE);
    });
  });
});
